import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'

import ethIcon from '../../assets/icons/eth.png';
import switchIcon from '../../assets/icons/switch.png';
import uniswapIcon from '../../assets/icons/uniswap.png';
import walletIcon from '../../assets/icons/wallet.png';

import alphaIcon from '../../assets/images/alpha_icon.png';

import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';

import { useSelector } from 'react-redux';
import './Home.scss';
import {mintEnabled, setweb3provider} from '../../action/CounterAction';
import reduxStore from '../../ReduxStore';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import Header from '../Header/Header'

const abi = require('../../helper/abi.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function Home() {

  const web3provider = useSelector(state => state.web3Provider);

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  useEffect(async() => {
    //Runs only on the first render
    if(web3provider){
    }else{
    }
  }, [web3provider]);

  return (
    <>
    <motion.section 
    {...paragraphAnimation}
    id='Home' className='Home'>
      <div className='Home__content'>
        <div className='home-flex-container'>
          <div className='home-flex-item-left' style={{color:'white'}}>
            {/* { !web3provider &&  
              <div className='mintBtn' onClick={connect}>
                CONNECT Wallet
              </div>
            } */}
            {/* <div className='address' style={{marginBottom:"10px",color:'white'}}>
              { address &&
                  <p style={{fontSize:"32px",fontWeight:"800"}}>{ address.substring(0,3)}...{address.substring(address.length-4,address.length) }</p>
              }
            </div> */}

            <div className='home-text-container'>
                  DON'T FADE THE 
            </div>

            <div className="alphahome-text-container">
                  $ALPHA
            </div>

            <div className="discord-text-container">
                  JOIN THE DISCORD
            </div>

            <div className='ecosystem-text-container'>
                  AN ECOSYSTEM IN ANOTHER DIMENSION.
            </div>

            
            {/* <div className='address' style={{marginBottom:"10px",color:'white'}}>
              <p style={{fontSize:"44px",fontWeight:"800"}}>{supply} / {maxSupply} Minted</p>
            </div>

            <div className='address'>
              <p style={{fontSize:"18px",fontWeight:"800",color:'white'}}>Amount</p>
            </div>

            <div className='amountBtn'>
              <div className='decrement' onClick={decrement}>
              </div>

              <div className='amountTxt'>
                {amount}
              </div>

              <div className='increment' onClick={increment}>
              </div>
            </div> */}


          </div>
        </div>
        <div className='section-text-container'>
              How To Buy
        </div>
        <div className='home-flex-container'>

            <div className='home-flex-item-buy'>
                <div className='item-icon'>
                    <img src={walletIcon}></img>
                </div>

                <div className='title-home-item'>
                    CREATE A WALLET
                </div>

                <div className='content-home-item'>
                  Download Metamask or your wallet of choice from the app store  or Google Play store for free. 
                  Desktop users, download the Google Chrome extension by going to metamask.io.
                </div>
            </div>

            <div className='home-flex-item-buy'>
                <div className='item-icon'>
                    <img src={ethIcon}></img>
                </div>

                <div className='title-home-item'>
                    GET SOME ETH
                </div>

                <div className='content-home-item'>
                Have ETH in your wallet to switch to $ALPHA. If you don’t have ETH, you can buy directly on metamask, transfer from another wallet, or buy on an exchange and send it to your wallet.
                </div>
            </div>

            <div className='home-flex-item-buy'>
                <div className='item-icon'>
                    <img src={uniswapIcon}></img>
                </div>

                <div className='title-home-item'>
                    GO TO UNISWAP
                </div>

                <div className='content-home-item'>
                Go to app.uniswap.org. Connect your wallet. Paste the $alpha token address into Uniswap, select $alpha Token, and confirm. When Metamask prompts you for a wallet signature, sign.
                </div>
            </div>

            <div className='home-flex-item-buy'>
                <div className='item-icon'>
                    <img src={switchIcon}></img>
                </div>

                <div className='title-home-item'>
                    SWITCH ETH FOR $ALPHA
                </div>

                <div className='content-home-item'>
                switch ETH for $alpha. We have ZERO taxes so you don't need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.
                </div>
            </div>
        </div>

        <div className='home-bottom-logo'>
            <img src={alphaIcon}>

            </img>
        </div>
        <footer className="footer">
          <div className="footer__section"> 
            <a href='https://etherscan.io/token/0x38f9bb135ea88033f4377b9ea0fb5cfb773fec2f'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
            <a href='https://discord.com/invite/cGBG3Q9RdB'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
            <a href='https://twitter.com/ALPHAT0KEN'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
            <a href='https://opensea.io/collection/sacred-shard'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
            <a href='https://t.me/+T0KeRpvqgAU5Yjgx'> <img className="footer__icon" src={tg} alt="Icon 1"/></a>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x70235a346a1ec1d7a40181ff88a3a2e5260e1d04'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
            <a href='mailto:alpha.support@gmail.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
          </div>
          <div className="footer__section">
            $Alpha
          </div>
          <div className="footer__section">
            <p className="footer__copyright">© {new Date().getFullYear()} Copyrights & Protected</p>
          </div>
        </footer>
      </div>
    </motion.section>
    </>
  );
}
